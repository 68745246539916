<!--
@description: userBox
@author: fc
@createtime:  2021年11月16日 18:16:15
-->
<template>
  <div>
    <div class="userBar" v-if="isLogin">
      <div class="userbar-left">
        <span class="username" :title="username">@{{ username }}</span>
        <address class="email" :title="email" v-if="email && email != 'email'">
          {{
            `${email.slice(0, 5)}...${email.slice(
              email.length - 4,
              email.length
            )}`
          }}
        </address>
      </div>
      <span class="divide-line">|</span>
      <img class="avatar userbar-right" :src="img" @mouseover="showUserMenu" />
      <div class="usermenu" v-if="isShowMenu" @mouseleave="hideUserMenu">
        <a @click="jumpToUserCenter" class="usercenter">{{
          $t("lang.usercenter")
        }}</a>
        <a @click="userLogout" class="logout" name="logout">{{
          $t("lang.logout")
        }}</a>
      </div>
    </div>
    <div style="display: flex" v-else>
      <a class="sign-in" @click="jumpToUnipass">{{ $t("lang.SignIn") }}</a>
    </div>
  </div>
</template>

<script>
import Session from "@/utils/session.js";
import { mapGetters, mapMutations } from "vuex";
import { imagesCache, avatarDefault } from "@data/avatar.js";
export default {
  data() {
    return {
      isShowMenu: false,
      isLogin: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    username() {
      if (this.userInfo && this.userInfo.name) {
        return this.userInfo.name;
      } else {
        return "username";
      }
    },
    email() {
      if (this.userInfo && this.userInfo.email) {
        return this.userInfo.email;
      } else {
        return "email";
      }
    },
    img() {
      if (this.userInfo && this.userInfo.avatar) {
        if (this.userInfo.avatar.length > 2) {
          return this.userInfo.avatar;
        } else {
          return imagesCache[this.userInfo.avatar];
        }
      } else {
        return avatarDefault;
      }
    },
  },
  watch: {
    username(val) {
      this.isLogin = val && val != "username";
    },
  },
  mounted() {
    let ses = new Session();
    this.isLogin = ses.getSession("isLogin", "local");
  },
  methods: {
    ...mapMutations(["setUserInfo"]),
    showUserMenu() {
      this.isShowMenu = true;
    },
    hideUserMenu() {
      this.isShowMenu = false;
    },
    userLogout() {
      this.isLogin = false;
      this.setUserInfo(null);
      if (this.$route.name != "coopList_pc") {
        this.$router.push({
          name: "coopList_pc",
        });
      }
    },
    jumpToUserCenter() {
      this.$router.push({
        name: "user_pc",
        query: {
          id: this.userInfo.id,
        },
      });
    },
    jumpToUnipass() {
      let ses = new Session();
      let isLogin = ses.getSession("isLogin", "local");
      console.log("isLogin", isLogin);
      if (!isLogin || isLogin == "false") {
        this.isShowRedirectDialog = true;
        this.loginUnipass();
      }
    },
    /**
     * 处理登录跳转
     */
    loginUnipass() {
      const UNIPASS_BASE = process.env.VUE_APP_UNIPASS_URL;
      const unipassJump = function (type, data = {}) {
        data.success_url = encodeURIComponent(window.location.href);
        const query = Object.keys(data)
          .map((key) => `${key}=${data[key]}`)
          .join("&");
        const url = `${UNIPASS_BASE}/${type}?${query}`;
        window.open(url);
        // window.location.href = url;
      };
      unipassJump("login");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/var.scss";
.userBar {
  height: 36px;
  display: inline-flex;
  align-items: center;
  background: #fff;
  border-radius: 30px;
  width: 154px;
  padding: 4px 6px 4px 20px;
  margin: 16px 0px;
  .userbar-left {
    width: 104px;
    overflow: hidden;
    .username {
      font-size: 1.4rem;
      color: $color-text;
      width: 100%;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: default;
      max-height: 2rem;
      max-width: 10rem;
      white-space: nowrap;
    }
    .email {
      color: $color-text-gray;
      font-size: 1.4rem;
      font-style: unset;
      cursor: pointer;
    }
  }
  .divide-line {
    margin: 0 10px;
    width: 2px;
    height: 38px;
    background: $border-color;
    z-index: 2;
    color: transparent;
  }
  .avatar {
    width: 36px;
    height: 36px;
    cursor: pointer;
    border-radius: 10rem;
  }
  .usermenu {
    position: absolute;
    display: block;
    top: 68px;
    right: 52px;
    width: 100px;
    overflow: hidden;
    padding: 8px 0;
    background: #fff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 15%);
    border-radius: 12px;
    border: 0;
    a {
      background: #fff;
      color: #333;
      text-decoration: none;
      display: block;
      font-size: 1.4rem;
      text-align: left;
      padding-left: 20px;
      cursor: pointer;
    }
    a:hover {
      color: $color-orange;
      text-decoration: none;
      background: 0 0;
    }
  }
}
.sign-in {
  cursor: pointer;
  margin-bottom: 0;
  margin-top: 0;
  height: 76px;
  display: flex;
  align-items: center;
}
</style>

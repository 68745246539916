<!--
@description:  预告页头部
@author: fc
@createtime: 2021年10月21日 11:19:56
-->
<template>
  <div
    :class="isHideHeader ? 'header is-header-hide' : 'header'"
    style="background: transparent"
  >
    <div class="nav-container">
      <!-- <span class="logo" @click="initScenes">
        <img height="58px" src="../../assets/coop.png" />
      </span> -->
      <a
        :class="$route.name == nav.name ? 'nav nav-active' : 'nav'"
        v-for="nav of navs"
        :key="nav.id"
        @click="handleRouteChange(nav)"
        >{{ $t(`lang.${nav.content}`) }}</a
      >
    </div>
    <Search></Search>
    <div class="header-right">
      <div class="language-change">
        <el-button
          type="text"
          :class="[
            'language-ele',
            'text',
            lang == 'zh-CN' ? 'active-lang' : '',
          ]"
          @click="changeLanguage('zh-CN')"
          >中</el-button
        >
        <span class="language-ele">|</span>
        <el-button
          type="text"
          :class="[
            'language-ele',
            'text',
            lang == 'en-US' ? 'active-lang' : '',
          ]"
          @click="changeLanguage('en-US')"
          >EN</el-button
        >
      </div>
      <UserBar></UserBar>
    </div>
  </div>
</template>

<script>
import Search from "./search_pc.vue";
import UserBar from "./userBar_pc.vue";
export default {
  name: "NoticeHeader",
  props: ["isHideHeader"],
  components: {
    Search,
    UserBar,
  },
  data() {
    return {
      navs: [
        {
          id: 1,
          content: "home",
          name: "coopList_pc",
        },
        {
          id: 2,
          content: "about",
          name: "about_pc",
        },
      ],
      lang: "zh-CN",
    };
  },
  methods: {
    initScenes() {
      this.$router.push({ name: "coopList_pc" });
    },
    handleRouteChange(nav) {
      if (nav.name != this.$route.name) {
        this.$router.push({
          name: nav.name,
        });
      }
    },
    changeLanguage(type) {
      if (type === "zh-CN") {
        this.lang = "zh-CN";
        this.$i18n.locale = this.lang; //关键语句
      } else {
        this.lang = "en-US";
        this.$i18n.locale = this.lang; //关键语句
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/var.scss";
.is-header-hide {
  opacity: 0;
}
.header {
  position: absolute;
  display: flex;
  width: 100%;
  font-size: 1.6rem;
  transition: opacity 0.3s;
  z-index: 1;
  .logo {
    width: fit-content;
    float: left;
    margin-left: 30px;
    display: flex;
    img {
      height: 45px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
  .nav-container {
    font-weight: 400;
    font-size: 1.6rem;
    margin: 0;
    padding: 0;
    width: fit-content;
    height: 76px;
    display: inline-flex;
    align-items: center;
    // margin-left: 30px;
    color: $color-primary;
    float: left;
    .nav {
      margin-left: 44px;
      color: aliceblue;
    }
    .nav-active,
    .nav:hover {
      color: $color-primary;
      cursor: pointer;
      border-bottom: 3px solid $color-orange;
    }
  }
  .header-right {
    float: right;
    display: inline-flex;
    margin-right: 50px;
    margin-left: 50px;
    .language-change {
      height: 76px;
      display: inline-flex;
      align-items: center;
      margin: 0px 50px;
      .language-ele {
        color: $color-primary;
        font-size: 1.6rem;
      }
      .text:hover {
        text-shadow: 1px 1px rgb(175, 64, 0);
      }
      .active-lang {
        color: $color-orange;
      }
      :nth-child(2) {
        margin: 0px 5px;
      }
    }
  }
}
</style>

<style lang="scss">
.language-change .el-button--text {
  border: unset;
}
</style>

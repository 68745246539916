<template>
  <div
    class="info-collect-dialog newevent-dialog-wrap"
    v-if="isShowInfoCollectDialog"
  >
    <div class="collect-dialog new-event-dialog">
      <div class="dialog-header">
        <i class="el-icon-close" @click="closeDialog"></i>
        <h2 style="text-align: center">
          {{
            collectType == "claimContact" || collectType == "contact"
              ? $t("lang.confirmContact")
              : $t("lang.denyReason")
          }}
        </h2>
      </div>
      <div class="event-container" v-if="collectType == 'denyReason'">
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          label-position="top"
          class="event-ruleForm"
        >
          <span class="form-tip">{{ $t("lang.denyReasonTip") }}</span>
          <el-form-item label="" prop="reason">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8 }"
              placeholder="请输入原因"
              v-model="ruleForm.reason"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="" class="event-control">
            <el-button class="event-submit" @click="submitReasonForm">{{
              $t("lang.submit")
            }}</el-button>
            <el-button class="event-submit" @click="submitReasonForm">{{
              $t("lang.continueWithoutReason")
            }}</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div
        class="event-container"
        v-else-if="collectType == 'claimContact' || collectType == 'contact'"
      >
        <el-form
          :model="contactForm"
          :rules="contactRules"
          ref="contactForm"
          label-position="top"
          class="event-ruleForm"
        >
          <el-form-item :label="$t('lang.formProp[6]')" prop="contact">
            <span class="form-tip"
              >您想让对方通过何种方式联系您，请至少填写一种联系方式</span
            >
            <div class="contact-container">
              <div class="contact-item">
                <span class="contact-type">
                  <img class="type-icon" src="../../../assets/discord.png" />
                  <span class="type-name">Discord</span>
                </span>
                <el-input
                  class="contact-input"
                  v-model="contacts.contact_discord"
                  placeholder="Include #Code"
                ></el-input>
              </div>
              <div class="contact-item">
                <span class="contact-type">
                  <img class="type-icon" src="../../../assets/email.png" />
                  <span class="type-name">Email</span>
                </span>
                <el-input
                  class="contact-input"
                  v-model="contacts.contact_email"
                  placeholder="aaa@abc.com"
                ></el-input>
              </div>
              <div class="contact-item">
                <span class="contact-type">
                  <img class="type-icon" src="../../../assets/wechat.png" />
                  <span class="type-name">Wechat</span>
                </span>
                <el-input
                  class="contact-input"
                  v-model="contacts.contact_wechat"
                  placeholder="Wechat ID"
                ></el-input>
              </div>
              <div v-show="contacts.others.length > 0">
                <div
                  class="contact-item"
                  v-for="item of contacts.others"
                  :key="item.id"
                >
                  <span class="contact-type">
                    <img class="type-icon" src="../../../assets/other.png" />
                    <span
                      class="type-name"
                      v-show="!item.isShowInput"
                      @click="item.isShowInput = true"
                      >{{
                        item.contact_otherType
                          ? item.contact_otherType
                          : "Other"
                      }}</span
                    >
                    <el-input
                      v-show="item.isShowInput"
                      class="other-type-name"
                      v-model="item.contact_otherType"
                      placeholder="Type"
                      @blur="otherTypeBlur(item)"
                      @change="handleOtherItemChange"
                    ></el-input>
                  </span>
                  <el-input
                    class="contact-input"
                    v-model="item.contact_other"
                    placeholder="Other method"
                    @change="handleOtherItemChange"
                  ></el-input>
                  <i class="el-icon-close" @click="deleteOtherItem(item)"></i>
                </div>
              </div>
              <el-button
                class="contact-item add-other-contact"
                @click="addOtherContact"
                >+ 添加其他联系方式</el-button
              >
            </div>
          </el-form-item>
          <el-form-item label="" class="event-control">
            <el-button class="event-submit" @click="submitContactForm"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    var validateContact = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请至少填写一种联系方式"));
      } else {
        let obj = JSON.parse(value);
        let hasWrite =
          obj.contact_discord ||
          obj.contact_email ||
          obj.contact_wechat ||
          (obj.length > 0 &&
            obj.findIndex(
              (ele) =>
                ele.contact_otherType.trim() != "" &&
                ele.contact_other.trim() != ""
            )) >= 0;
        if (!hasWrite) {
          callback(new Error("请至少填写完整一种联系方式"));
        } else {
          callback();
        }
      }
    };
    return {
      ruleForm: {
        reason: "",
      },
      contactForm: {
        contact:
          '{"contact_discord":"","contact_email":"","contact_wechat":"", "others":[]}',
      },
      contactRules: {
        contact: [
          {
            required: true,
            type: "string",
            validator: validateContact,
            trigger: "blur",
          },
        ],
      },
      contacts: {
        contact_discord: "",
        contact_email: "",
        contact_wechat: "",
        others: [],
      },
    };
  },
  computed: {
    ...mapGetters(["collectType", "isShowInfoCollectDialog", "userInfo"]),
  },
  watch: {
    "contacts.contact_discord": {
      handler(newValue, oldValue) {
        let contactObj = JSON.parse(this.contactForm.contact);
        if (!newValue) {
          contactObj.contact_discord = oldValue;
        } else {
          contactObj.contact_discord = newValue;
        }
        this.contactForm.contact = JSON.stringify(contactObj);
      },
      deep: true,
    },
    "contacts.contact_email": {
      handler(newValue, oldValue) {
        let contactObj = JSON.parse(this.contactForm.contact);
        if (!newValue) {
          contactObj.contact_email = oldValue;
        } else {
          contactObj.contact_email = newValue;
        }
        this.contactForm.contact = JSON.stringify(contactObj);
      },
      deep: true,
    },
    "contacts.contact_wechat": {
      handler(newValue, oldValue) {
        let contactObj = JSON.parse(this.contactForm.contact);
        if (!newValue) {
          contactObj.contact_wechat = oldValue;
        } else {
          contactObj.contact_wechat = newValue;
        }
        this.contactForm.contact = JSON.stringify(contactObj);
      },
      deep: true,
    },
    "userInfo.contact"() {
      this.updataContacts();
    },
  },
  mounted() {
    this.updataContacts();
  },
  methods: {
    ...mapMutations(["setDenyReason", "setContact", "setInfoCollectDialog"]),
    updataContacts() {
      console.log(this.userInfo);
      if (this.userInfo.contact) {
        let contact = JSON.parse(this.userInfo.contact);
        let index = "discord,wechat,email".indexOf(contact.contactType);
        switch (index) {
          case -1:
            this.contacts.others = [
              {
                contact_other: contact.contactDetail,
                contact_otherType: contact.contactType,
                isShowInput: false,
                id: this.contacts.others.length + 1,
              },
            ];
            break;
          case 0:
            this.contacts.contact_discord = contact.contactDetail;
            break;
          case 8:
            this.contacts.contact_wechat = contact.contactDetail;
            break;
          case 15:
            this.contacts.contact_email = contact.contactDetail;
            break;
          default:
            break;
        }
        this.contactForm.contact = JSON.stringify(this.contacts);
      }
    },
    initRuleInfo() {
      this.contactForm = {
        contact:
          '{"contact_discord":"","contact_email":"","contact_wechat":"", "others":[]}',
      };
      this.ruleForm = {
        reason: "",
      };
      this.contacts = {
        contact_discord: "",
        contact_email: "",
        contact_wechat: "",
        others: [],
      };
    },
    handleOtherItemChange() {
      let temp = JSON.parse(this.contactForm.contact);
      temp.others = this.contacts.others;
      this.contactForm.contact = JSON.stringify(temp);
    },
    addOtherContact() {
      this.contacts.others.push({
        contact_other: "",
        contact_otherType: "",
        isShowInput: true,
        id: this.contacts.others.length + 1,
      });
      this.handleOtherItemChange();
    },
    deleteOtherItem(item) {
      let index = this.contacts.others.findIndex((ele) => ele.id == item.id);
      this.contacts.others.splice(index, 1);
      this.handleOtherItemChange();
    },
    submitReasonForm() {
      let vm = this;
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          vm.setDenyReason(vm.ruleForm.reason);
          vm.closeDialog();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    submitContactForm() {
      let vm = this;
      this.$refs["contactForm"].validate((valid) => {
        if (valid) {
          vm.setContact([vm.contactForm.contact, vm.collectType]);
          vm.closeDialog();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    otherTypeBlur(item) {
      item.isShowInput = false;
    },
    closeDialog() {
      this.initRuleInfo();
      this.setInfoCollectDialog([false, this.collectType]);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/var.scss";
.newevent-dialog-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: 999;
  background: #00000080;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .collect-dialog {
    position: relative;
    width: 40%;
    min-width: 700px;
    height: 650px;
    margin: auto;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px;
    background-image: url("../../../assets/dialog-bg.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    .dialog-header {
      width: calc(100% - 40px);
      position: relative;
      padding: 20px;
      text-align: center;
    }
    .event-container {
      width: 100%;
      height: calc(100% - 122px);
      margin-top: 20px;
      overflow: auto;
    }
    .el-icon-close {
      border-radius: 12px;
      position: absolute;
      right: 20px;
      top: 20px;
    }
    .event-ruleForm {
      width: 50%;
      margin-left: 25%;
      .form-tip {
        color: $color-text-gray;
        font-size: 1.4rem;
      }
      .contact-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        left: 0px;
        flex: none;
        .contact-item {
          position: relative;
          border-radius: 8px;
          &:nth-child(n + 1) {
            margin-top: 16px;
          }
          display: flex;
          justify-content: space-between;
          background: #ebebeb;
          .contact-input {
            width: calc(100% - 156px);
          }
          .el-icon-close {
            font-size: 2rem;
            right: -40px;
          }
        }
        .add-other-contact {
          background: #f6f6f6;
          border: 1px solid #131214;
          box-sizing: border-box;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 56px;
          font-size: 1.6rem;
        }
      }

      .event-control {
        display: flex;
        flex-direction: column;
        margin-top: 0px;
        .event-submit,
        .event-cancel {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 44px;
          font-size: 1.6rem;
          border-radius: 28px;
          &:hover {
            box-shadow: 1px 1px 5px $border-color;
          }
        }
        .event-submit {
          background-color: $color-orange;
          color: $color-primary;
        }
        .event-cancel {
          border: 1px solid $color-orange;
          background-color: $color-primary;
          color: $color-text;
          font-weight: 700;
        }
      }
    }
  }
}
</style>
<style lang="scss">
@import "@/style/var.scss";
.new-event-dialog .el-input__inner {
  height: 56px;
  line-height: 56px;
  border-radius: 8px;
}
.event-control {
  margin-top: 80px;
  display: flex;
  .el-button + .el-button {
    margin-left: 0;
    margin-top: 16px;
  }
}
.collect-dialog .event-container .el-form-item {
  margin-bottom: 10px;
}
.other-type-name .el-input__inner {
  height: 40px;
}
</style>

<!--
@description: home
@author: fc
@createtime: 2021年11月16日 17:06:49
-->
<template>
  <div class="home">
    <div class="header-bg"></div>
    <Header :isHideHeader="isHideHeader"></Header>
    <div
      class="scenes"
      v-loading="scenesloading"
      :element-loading-text="$t('lang.loading')"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 250, 233, 0.8)"
    >
      <router-view></router-view>
      <redirect-dialog
        v-if="isShowRedirectDialog"
        @closeDialog="isShowRedirectDialog = false"
      ></redirect-dialog>
      <new-event-dialog></new-event-dialog>
      <collect-dialog></collect-dialog>
      <module-dialog></module-dialog>
    </div>
  </div>
</template>

<script>
import Header from "./header_pc.vue";
import { Blake2bHasher } from "@lay2/pw-core";
import Session from "@/utils/session.js";
import { mapGetters, mapMutations } from "vuex";
import NewEventDialog from "./newEventDialog_pc.vue";
import CollectDialog from "./collectDialog_pc.vue";
import RedirectDialog from "./redirectDialog_pc.vue";
import ModuleDialog from "./moduleDialog_pc.vue";
import { login } from "@/api/public.js";
import { getQueryParams } from "@/utils";
export default {
  name: "avatar",
  components: {
    Header,
    NewEventDialog,
    RedirectDialog,
    CollectDialog,
    ModuleDialog,
  },
  data() {
    return {
      isHideHeader: false,
      i: 0,
      isShowRedirectDialog: false,
    };
  },
  computed: {
    ...mapGetters(["scenesloading"]),
  },
  async mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
    //监听当前url，判断是否已经登录
    try {
      const url = new URL(window.location.href);
      const ret = JSON.parse(url.searchParams.get("unipass_ret"));
      let ses = new Session();
      if (ret && ret.code == 200) {
        try {
          let res = await this.loginCoop(ret.data);
          if (res && res.name) {
            ses.setSession("isLogin", true, "local");
            let bar = JSON.stringify(res);
            ses.setSession("bar", bar, "local");
            this.isShowRedirectDialog = false;
            const queryParams = getQueryParams();
            delete queryParams.unipass_ret;
            let contact = res.contact;
            let jsonContact;
            try {
              jsonContact = JSON.parse(contact);
            } catch (error) {
              jsonContact = null;
            }
            if (contact && jsonContact && jsonContact.contactDetail) {
              this.$router.push({
                name: "coopList_pc",
                query: queryParams,
              });
            } else {
              this.$router.push({
                name: "user_pc",
                query: {
                  id: res.id,
                  init: true,
                },
              });
            }
          }
        } catch (error) {
          console.log(error);
          ses.setSession("isLogin", false, "local");
        }
      } else {
        let isLogin = ses.getSession("isLogin", "local");
        if (!isLogin || isLogin == "false") {
          this.isShowRedirectDialog = true;
          this.loginUnipass();
        } else {
          let bar = ses.getSession("bar", "local");
          let userInfo = JSON.parse(bar);
          this.setUserInfo(userInfo);
        }
      }
    } catch (error) {
      console.log(error);
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    ...mapMutations(["setUserInfo"]),
    /**
     * 处理页面滚动时顶部的隐藏
     */
    handleScroll() {
      var scrollTop = document.querySelector(".scenes").scrollTop;
      var scroll = scrollTop - this.i;
      this.i = scrollTop;
      if (scroll > 0) {
        this.isHideHeader = true;
      } else {
        this.isHideHeader = false;
      }
    },
    /**
     * 处理登录跳转
     */
    loginUnipass() {
      const UNIPASS_BASE = process.env.VUE_APP_UNIPASS_URL;
      const unipassJump = function (type, data = {}) {
        data.success_url = encodeURIComponent(window.location.href);
        const query = Object.keys(data)
          .map((key) => `${key}=${data[key]}`)
          .join("&");
        const url = `${UNIPASS_BASE}/${type}?${query}`;
        window.open(url);
        // window.location.href = url;
      };
      unipassJump("login");
    },
    /**
     * 从后台登录
     */
    async loginCoop({ email, pubkey }) {
      const pubKeyBuffer = Buffer.from(pubkey.replace("0x", ""), "hex");
      let hash = new Blake2bHasher()
        .update(pubKeyBuffer.buffer)
        .digest()
        .serializeJson()
        .slice(0, 42);
      let userinfo = {};
      let vm = this;
      //从后台登录
      await login({
        unipassId: hash,
      })
        .then((res) => {
          if (res) {
            let user = res.data.user;
            userinfo = {
              name: user.username,
              email: email,
              avatar: user.avatar,
              id: user.id,
              introduction: user.introduction,
              isInfoSet: user.isInfoSet,
              token: res.data.token,
              contact: user.contact,
            };
            vm.setUserInfo(userinfo);
          }
        })
        .catch((e) => console.log(e));
      return userinfo;
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #fffae9;
}
.scenes {
  height: 100%;
  width: 100%;
  overflow: auto;
  position: absolute;
}
.header-bg {
  background-image: url("../../../assets/header-bg.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 288px;
}
</style>
<style lang="scss">
@import "@/style/var.scss";
@import "@/style/module.scss";
.jump-dialog .el-icon-loading {
  margin-left: 20px;
}
</style>

<!--
@description: search
@author: fc
@createtime:  2021年11月16日 18:16:15
-->
<template>
  <div class="search-container">
    <div class="search-wrap">
      <FInput
        icon="search"
        placeholder="搜索"
        @change="handleSearchChange"
        @input="loading = true"
      />
      <transition name="el-fade-in-linear">
        <div class="result-list rest-loading" v-show="loading">
          {{ $t("lang.loading") }}
        </div>
      </transition>
      <transition name="el-fade-in-linear">
        <ul class="result-list" v-show="isShow">
          <li
            v-for="item in userOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            @click="handleOpenProfile(item.value)"
          >
            <div class="select-wrap">
              <img :src="item.avatar" class="select-avatar" />
              <span class="select-username">{{ item.label }}</span>
            </div>
          </li>
        </ul>
      </transition>
    </div>
  </div>
</template>

<script>
import FInput from "@/components/input.vue";
import { matchUsers } from "@/api/public.js";
import { imagesCache, avatarDefault } from "@data/avatar.js";

export default {
  components: {
    FInput,
  },
  data() {
    return {
      userOptions: [],
      isShow: false,
      loading: false,
    };
  },
  methods: {
    async handleSearchChange(query) {
      let vm = this;
      if (query !== "") {
        await matchUsers({
          keyword: query,
        })
          .then((res) => {
            vm.loading = false;
            if (res.code == 200) {
              vm.isShow = true;
              vm.userOptions = res.data.map((item) => {
                return {
                  avatar:
                    item.avatar.length > 2
                      ? item.avatar
                      : imagesCache[item.avatar]
                      ? imagesCache[item.avatar]
                      : avatarDefault,
                  value: item.id,
                  email: item.email,
                  label: item.username,
                };
              });
            } else {
              vm.$message.warning(res.message);
              vm.isShow = false;
            }
          })
          .catch((e) => {
            vm.loading = false;
            vm.isShow = false;
            console.log(e);
            vm.$message.warning(e.message);
          });
      } else {
        vm.options = [];
        vm.isShow = false;
        vm.loading = false;
      }
    },
    handleOpenProfile(id) {
      let routeUrl = this.$router.resolve({
        path: "/user_profile_wap",
        query: {
          id: id,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.search-container {
  display: flex;
  height: 76px;
  flex: 1;
  justify-content: flex-end;
  .search-wrap {
    position: relative;
    display: inline-flex;
    align-items: center;
    ul {
      padding-inline-end: 0rem;
      padding-inline-start: 0rem;
      margin-block-start: 0rem;
      margin-block-end: 0rem;
      list-style-type: none;
      li {
        cursor: pointer;
        &:nth-child(n + 1) {
          margin-top: 1rem;
        }
      }
    }
    .result-list {
      position: absolute;
      top: 6rem;
      background: #fff;
      width: calc(100% - 2rem);
      display: flex;
      flex-direction: column;
      border-radius: 1rem;
      padding: 1rem;
      .select-wrap {
        display: flex;
        align-items: center;
      }
      .select-avatar {
        border-radius: 100%;
        width: 20px;
        height: 20px;
        max-width: 100%;
        max-height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        opacity: 1;
        float: left;
      }
      .select-username {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 10px;
        float: left;
      }
    }
    .rest-loading {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 1rem 0px;
      font-size: 1.4rem;
    }
  }
}
</style>

<style>
.search-wrap .wrapper > input.inline-icon-left {
  color: #000;
}
</style>

<!--
@description: newEventDialog
@author: fc
@createtime: 2021年11月18日 17:00:19 
-->
<template>
  <div
    class="newevent-dialog-wrap"
    v-if="
      isShowNewEventDialog || isShowEditEventDialog || isShowReadOnlyEventDialog
    "
  >
    <div class="new-event-dialog">
      <div class="dialog-header">
        <i class="el-icon-close" @click="closeDialog"></i>
        <h2 style="text-align: center">
          {{ dialogHeader }}
        </h2>
      </div>
      <div
        class="event-container"
        id="fc-new-dialog"
        v-loading="infoLoading"
        :element-loading-text="$t('lang.loading')"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 250, 233, 0.8)"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-position="top"
          class="event-ruleForm"
          :disabled="isShowReadOnlyEventDialog"
        >
          <el-form-item :label="$t('lang.formProp[0]')" prop="id">
            <el-switch
              v-show="isShowEditEventDialog"
              style="display: flex; width: fit-content"
              v-model="isInviteDirect"
              :active-text="$t('lang.inviteMethodOne')"
              :inactive-text="$t('lang.inviteMethodTwo')"
            >
            </el-switch>
            <span class="form-tip">{{ $t("lang.formHolder[9]") }}</span>
            <div v-show="isInviteDirect">
              <span class="form-tip">{{ $t("lang.formHolder[10]") }}</span>
              <div class="input-wrap">
                <el-select
                  class="invite-input"
                  popper-class="newevent-dialog"
                  v-model="ruleForm.id"
                  filterable
                  remote
                  clearable
                  :placeholder="$t('lang.formHolder[0]')"
                  :remote-method="remoteMethod"
                  :loading="selectLoading"
                >
                  <el-option
                    v-for="item in userOptions"
                    :key="item.id"
                    :label="item.label"
                    :value="item.value"
                  >
                    <div class="select-wrap">
                      <img
                        :src="item.avatar"
                        :datas="item.avatar"
                        class="select-avatar"
                      />
                      <span class="select-username">{{ item.label }}</span>
                      <span class="icon-wrap"
                        ><i
                          class="el-icon-view"
                          @click.stop.prevent="handleOpenProfile(item.value)"
                        ></i
                      ></span>
                    </div>
                  </el-option>
                </el-select>
                <span class="invite-search-container">
                  <el-button class="invite-search">{{
                    $t("lang.formHolder[1]")
                  }}</el-button>
                </span>
              </div>
            </div>
            <div v-show="!isInviteDirect">
              <span class="form-tip">{{ $t("lang.chooseClaims") }}</span>
              <div class="input-wrap flex-col" v-show="isShowEditEventDialog">
                <el-select
                  class="invite-input"
                  popper-class="newevent-dialog"
                  v-model="ruleForm.id"
                  filterable
                  :clearable="true"
                >
                  <el-option
                    v-for="item in applicationOptions"
                    :key="item.applyUser.id"
                    :label="item.applyUser.username"
                    :value="item.applyUser.id"
                  >
                    <div class="select-wrap">
                      <img :src="item.avatar" class="select-avatar" />
                      <span class="select-username">{{ item.label }}</span>
                      <span class="icon-wrap"
                        ><i
                          class="el-icon-view"
                          @click.stop.prevent="
                            handleOpenProfile(item.applyUser.id)
                          "
                        ></i
                      ></span>
                    </div>
                  </el-option>
                </el-select>
              </div>
            </div>
          </el-form-item>
          <el-form-item :label="$t('lang.formProp[1]')" prop="bounty">
            <span class="form-tip">{{ $t("lang.formHolder[2]") }}</span>
            <div style="position: relative">
              <el-input
                class="bounty-input"
                v-model.number="ruleForm.bounty"
                placeholder="0"
              ></el-input>
              <span class="bounty-unit">$ COOP</span>
            </div>
          </el-form-item>
          <el-form-item :label="$t('lang.formProp[2]')" prop="topic">
            <el-input
              class="event-topic"
              v-model="ruleForm.topic"
              :placeholder="$t('lang.formHolder[3]')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('lang.formProp[3]')" prop="description">
            <el-input
              class="event-description"
              type="textarea"
              :autosize="{ minRows: 8, maxRows: 12 }"
              :placeholder="$t('lang.formHolder[4]')"
              v-model="ruleForm.description"
            >
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('lang.formProp[4]')" prop="datetime">
            <el-date-picker
              class="data-pick"
              v-model="ruleForm.datetime"
              type="datetimerange"
              range-separator="—"
              :start-placeholder="$t('lang.formHolder[5]')"
              :end-placeholder="$t('lang.formHolder[6]')"
              format="yyyy-MM-dd HH:mm"
              align="right"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('lang.formProp[5]')" prop="location">
            <el-input
              :placeholder="$t('lang.formHolder[7]')"
              v-model="ruleForm.location"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('lang.formProp[6]')" prop="contact">
            <span class="form-tip">{{ $t("lang.formHolder[8]") }}</span>
            <div class="contact-container">
              <div class="contact-item">
                <span class="contact-type">
                  <img class="type-icon" src="../../../assets/discord.png" />
                  <span class="type-name">Discord</span>
                </span>
                <el-input
                  class="contact-input"
                  v-model="contacts.contact_discord"
                  placeholder="Include #Code"
                ></el-input>
              </div>
              <div class="contact-item">
                <span class="contact-type">
                  <img class="type-icon" src="../../../assets/email.png" />
                  <span class="type-name">Email</span>
                </span>
                <el-input
                  class="contact-input"
                  v-model="contacts.contact_email"
                  placeholder="aaa@abc.com"
                ></el-input>
              </div>
              <div class="contact-item">
                <span class="contact-type">
                  <img class="type-icon" src="../../../assets/wechat.png" />
                  <span class="type-name">Wechat</span>
                </span>
                <el-input
                  class="contact-input"
                  v-model="contacts.contact_wechat"
                  placeholder="Wechat ID"
                ></el-input>
              </div>
              <div v-show="contacts.others.length > 0">
                <div
                  class="contact-item"
                  v-for="item of contacts.others"
                  :key="item.id"
                >
                  <span class="contact-type">
                    <img class="type-icon" src="../../../assets/other.png" />
                    <span
                      class="type-name"
                      v-show="!item.isShowInput"
                      @click="item.isShowInput = true"
                      >{{
                        item.contact_otherType
                          ? item.contact_otherType
                          : "Other"
                      }}</span
                    >
                    <el-input
                      v-show="item.isShowInput"
                      class="other-type-name"
                      v-model="item.contact_otherType"
                      placeholder="Type"
                      @blur="otherTypeBlur(item)"
                      @change="handleOtherItemChange"
                    ></el-input>
                  </span>
                  <el-input
                    class="contact-input"
                    v-model="item.contact_other"
                    placeholder="Other  method"
                    @change="handleOtherItemChange"
                  ></el-input>
                  <i class="el-icon-close" @click="deleteOtherItem(item)"></i>
                </div>
              </div>
              <el-button
                class="contact-item add-other-contact"
                @click="addOtherContact"
                >{{ $t("lang.formProp[7]") }}</el-button
              >
            </div>
          </el-form-item>
          <el-form-item
            label=""
            class="event-control"
            v-if="!isShowReadOnlyEventDialog"
          >
            <el-button class="event-submit" @click="submitForm">{{
              $t("lang.formProp[8]")
            }}</el-button>
            <el-button class="event-cancel">{{
              $t("lang.formProp[9]")
            }}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import {
  matchUsers,
  createEvent,
  editEvent,
  getEventDetail,
  getEventApplications,
} from "@/api/public.js";
import { imagesCache } from "@data/avatar.js";
import { formatDate } from "@/utils/index.js";
import { avatarDefault } from "../../../data/avatar";
export default {
  data() {
    var validateContact = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请至少填写一种联系方式"));
      } else {
        let obj = JSON.parse(value);
        let hasWrite =
          obj.contact_discord != "" ||
          obj.contact_email != "" ||
          obj.contact_wechat != "" ||
          (obj.others.length > 0 &&
            obj.others.findIndex(
              (ele) =>
                ele.contact_otherType.trim() != "" &&
                ele.contact_other.trim() != ""
            ) >= 0);
        if (!hasWrite) {
          callback(new Error("请至少填写完整一种联系方式"));
        } else {
          callback();
        }
      }
    };
    return {
      ruleForm: {
        id: "",
        description: "",
        topic: "",
        location: "",
        datetime: [new Date().getTime(), new Date() + 3600000],
        bounty: 0,
        contact:
          '{"contact_discord":"","contact_email":"","contact_wechat":"", "others":[]}',
      },
      rules: {
        id: [{ required: false }],
        description: [
          { required: false, type: "string" },
          {
            max: 300,
            message: "超出长度限制，最长300字",
            trigger: "change",
          },
        ],
        datetime: [
          {
            required: true,
            message: "请选择日期",
            trigger: "blur",
          },
        ],
        bounty: [
          {
            required: true,
            message: "请填写酬金",
            trigger: "blur",
          },
          {
            type: "number",
            message: "请填写正整数",
            trigger: "blur",
          },
        ],
        topic: [
          {
            required: true,
            type: "string",
            message: "请填写话题",
            trigger: "blur",
          },
        ],
        location: [
          {
            required: false,
            type: "string",
            message: "请填写位置",
            trigger: "blur",
          },
        ],
        contact: [
          {
            required: true,
            type: "string",
            validator: validateContact,
            trigger: "blur",
          },
        ],
      },
      contacts: {
        contact_discord: "",
        contact_email: "",
        contact_wechat: "",
        others: [],
      },
      userOptions: [],
      applicationOptions: [],
      loading: false,
      selectLoading: false,
      infoLoading: false,
      isInviteDirect: true, //是否直接邀请
    };
  },
  computed: {
    ...mapGetters([
      "isShowNewEventDialog",
      "isShowEditEventDialog",
      "isShowReadOnlyEventDialog",
      "userInfo",
      "editEventId",
      "readOnlyEventId",
    ]),
    dialogHeader() {
      if (this.isShowNewEventDialog) {
        return this.$t("lang.createNewInvite");
      } else if (this.isShowEditEventDialog) {
        return this.$t("lang.editEvent");
      } else if (this.isShowReadOnlyEventDialog) {
        return this.$t("lang.reviewEvent");
      } else {
        return this.$t("lang.createNewInvite");
      }
    },
  },
  watch: {
    "contacts.contact_discord": {
      handler(newValue, oldValue) {
        let contactObj = JSON.parse(this.ruleForm.contact);
        if (!newValue) {
          contactObj.contact_discord = oldValue;
        } else {
          contactObj.contact_discord = newValue;
        }
        this.ruleForm.contact = JSON.stringify(contactObj);
      },
      deep: true,
    },
    "contacts.contact_email": {
      handler(newValue, oldValue) {
        let contactObj = JSON.parse(this.ruleForm.contact);
        if (!newValue) {
          contactObj.contact_email = oldValue;
        } else {
          contactObj.contact_email = newValue;
        }
        this.ruleForm.contact = JSON.stringify(contactObj);
      },
      deep: true,
    },
    "contacts.contact_wechat": {
      handler(newValue, oldValue) {
        let contactObj = JSON.parse(this.ruleForm.contact);
        if (!newValue) {
          contactObj.contact_wechat = oldValue;
        } else {
          contactObj.contact_wechat = newValue;
        }
        this.ruleForm.contact = JSON.stringify(contactObj);
      },
      deep: true,
    },
    isShowNewEventDialog(val) {
      if (val) {
        this.updataContacts();
        this.$nextTick(() => {
          document.querySelector("#fc-new-dialog").scrollTop = 0;
        });
      }
    },
    isShowEditEventDialog(val) {
      if (val) {
        this.$nextTick(() => {
          document.querySelector("#fc-new-dialog").scrollTop = 0;
        });
        this.editRuleInfo();
      }
    },
    isShowReadOnlyEventDialog(val) {
      if (val) {
        this.$nextTick(() => {
          document.querySelector("#fc-new-dialog").scrollTop = 0;
        });
        this.readOnlyRuleInfo();
      }
    },
    "ruleForm.id": {
      handler(newValue) {
        if (!newValue) {
          this.handleClear();
        }
      },
      deep: true,
    },
    isInviteDirect(val) {
      if (!val) {
        this.handleClear();
      } else {
        this.userOptions = this.applicationOptions.slice();
      }
    },
    "userInfo.contact"() {
      this.updataContacts();
    },
  },
  mounted() {
    this.updataContacts();
    if (this.isShowEditEventDialog) {
      this.editRuleInfo();
    } else if (this.isShowReadOnlyEventDialog) {
      this.readOnlyRuleInfo();
    }
  },
  methods: {
    ...mapMutations([
      "setNewEventDialog",
      "setUpdataListByManul",
      "setUpdataUserEventByEdit",
      "setEditEventDialog",
      "setReadOnlyEventDialog",
    ]),
    updataContacts() {
      if (this.userInfo.contact) {
        let contact = JSON.parse(this.userInfo.contact);
        let index = "discord,wechat,email".indexOf(contact.contactType);
        switch (index) {
          case -1:
            this.contacts.others = [
              {
                contact_other: contact.contactDetail,
                contact_otherType: contact.contactType,
                isShowInput: false,
                id: this.contacts.others.length + 1,
              },
            ];
            break;
          case 0:
            this.contacts.contact_discord = contact.contactDetail;
            break;
          case 8:
            this.contacts.contact_wechat = contact.contactDetail;
            break;
          case 15:
            this.contacts.contact_email = contact.contactDetail;
            break;
          default:
            break;
        }
        this.ruleForm.contact = JSON.stringify(this.contacts);
      }
    },
    otherTypeBlur(item) {
      item.isShowInput = false;
    },
    closeDialog() {
      this.initRuleInfo();
      this.setNewEventDialog(false);
      this.setEditEventDialog([false, null]);
      this.setReadOnlyEventDialog([false, null]);
    },
    initRuleInfo() {
      this.ruleForm = {
        id: "",
        description: "",
        datetime: [
          new Date(2021, 11, 24, 10, 10),
          new Date(2021, 11, 25, 10, 10),
        ],
        bounty: 0,
        contact:
          '{"contact_discord":"","contact_email":"","contact_wechat":"", "others":[]}',
      };
      this.contacts = {
        contact_discord: "",
        contact_email: "",
        contact_wechat: "",
        others: [],
      };
      this.userOptions = [];
      this.applicationOptions = [];
      this.loading = false;
    },
    async editRuleInfo() {
      let vm = this;
      this.userOptions = [];
      this.applicationOptions = [];
      this.loading = false;
      this.infoLoading = true;
      try {
        let res = await getEventDetail({
          eventId: vm.editEventId,
        });
        let eventDetail = null;
        if (res.code == 200) {
          eventDetail = res.data;
          vm.eventDetailId = eventDetail.id;
          vm.ruleForm = {
            id: eventDetail.receiveUser ? eventDetail.receiveUser.id : "",
            topic: eventDetail.topic,
            description: eventDetail.description,
            location: eventDetail.location,
            datetime: [
              new Date(eventDetail.startTime),
              new Date(eventDetail.endTime),
            ],
            bounty: eventDetail.remuneration,
            contact: eventDetail.postUserContact,
          };
          if (eventDetail.receiveUser) {
            vm.userOptions = [
              {
                avatar: imagesCache[eventDetail.receiveUser.avatar]
                  ? imagesCache[eventDetail.receiveUser.avatar]
                  : eventDetail.receiveUser.avatar,
                value: eventDetail.receiveUser.id,
                label: eventDetail.receiveUser.username,
              },
            ];
          } else {
            vm.userOptions = [];
          }
          let contactNew = JSON.parse(eventDetail.postUserContact);
          vm.contacts = {
            contact_discord: contactNew.contact_discord,
            contact_email: contactNew.contact_email,
            contact_wechat: contactNew.contact_wechat,
            others: contactNew.others,
          };
          if (!eventDetail.receiveUser || !eventDetail.receiveUser.id) {
            let res = await getEventApplications({
              eventId: eventDetail.id,
            });
            if (res.code == 200) {
              res.data.forEach((val) => {
                if (val.applyUser && imagesCache[val.applyUser.avatar]) {
                  val.applyUser.avatar = imagesCache[val.applyUser.avatar];
                } else {
                  val.applyUser.avatar = avatarDefault;
                }
                val.avatar = val.applyUser.avatar;
                val.value = val.applyUser.id;
                val.label = val.applyUser.username;
              });
              vm.applicationOptions = res.data;
            }
          }
        } else {
          vm.$message.warning(res.message);
        }
      } catch (error) {
        console.log(error);
        this.setEditEventDialog([false, null]);
      }
      this.infoLoading = false;
    },
    async handleClear() {
      let res = await getEventApplications({
        eventId: this.eventDetailId,
      });
      if (res.code == 200) {
        res.data.forEach((val) => {
          if (imagesCache[val.applyUser.avatar]) {
            val.applyUser.avatar = imagesCache[val.applyUser.avatar];
          }
          val.avatar = val.applyUser.avatar;
          val.value = val.applyUser.id;
          val.label = val.applyUser.username;
        });
        this.applicationOptions = res.data;
      }
    },
    async readOnlyRuleInfo() {
      let vm = this;
      this.userOptions = [];
      this.loading = false;
      this.infoLoading = true;
      try {
        let res = await getEventDetail({
          eventId: this.readOnlyEventId,
        });
        let eventDetail = null;
        if (res.code == 200) {
          eventDetail = res.data;
          if (eventDetail.receiveUser) {
            this.userOptions = [
              {
                avatar: imagesCache[eventDetail.receiveUser.avatar]
                  ? imagesCache[eventDetail.receiveUser.avatar]
                  : eventDetail.receiveUser.avatar,
                value: eventDetail.receiveUser.id,
                label: eventDetail.receiveUser.username,
              },
            ];
          } else {
            this.userOptions = [];
          }
          this.ruleForm = {
            id: eventDetail.receiveUser ? eventDetail.receiveUser.id : "",
            topic: eventDetail.topic,
            description: eventDetail.description,
            location: eventDetail.location,
            datetime: [
              new Date(eventDetail.startTime),
              new Date(eventDetail.endTime),
            ],
            bounty: eventDetail.remuneration,
            contact: eventDetail.postUserContact,
          };
          let contactNew = JSON.parse(eventDetail.postUserContact);
          this.contacts = {
            contact_discord: contactNew.contact_discord,
            contact_email: contactNew.contact_email,
            contact_wechat: contactNew.contact_wechat,
            others: contactNew.others,
          };
        } else {
          vm.$message.warning(res.message);
        }
      } catch (error) {
        console.log(error);
        this.setReadOnlyEventDialog([false, null]);
      }
      this.infoLoading = false;
    },
    handleOtherItemChange() {
      let temp = JSON.parse(this.ruleForm.contact);
      temp.others = this.contacts.others;
      this.ruleForm.contact = JSON.stringify(temp);
    },
    async remoteMethod(query) {
      let vm = this;
      if (query !== "") {
        this.loading = true;
        await matchUsers({
          keyword: query,
        })
          .then((res) => {
            vm.loading = false;
            vm.userOptions = res.data.map((item) => {
              return {
                avatar: imagesCache[item.avatar]
                  ? imagesCache[item.avatar]
                  : item.avatar,
                value: item.id,
                label: item.username,
              };
            });
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.options = [];
      }
    },
    addOtherContact() {
      this.contacts.others.push({
        contact_other: "",
        contact_otherType: "",
        isShowInput: true,
        id: this.contacts.others.length + 1,
      });
      this.handleOtherItemChange();
    },
    deleteOtherItem(item) {
      let index = this.contacts.others.findIndex((ele) => ele.id == item.id);
      this.contacts.others.splice(index, 1);
      this.handleOtherItemChange();
    },
    submitForm() {
      let vm = this;
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let startTime = formatDate(
            vm.ruleForm.datetime[0],
            "yyyy-MM-dd HH:mm:ss"
          );
          let endTime = formatDate(
            vm.ruleForm.datetime[1],
            "yyyy-MM-dd HH:mm:ss"
          );
          if (this.isShowEditEventDialog) {
            editEvent({
              eventId: this.editEventId,
              receiveUserId: !vm.ruleForm.id ? "" : vm.ruleForm.id,
              remuneration: vm.ruleForm.bounty,
              topic: vm.ruleForm.topic,
              startTime: startTime,
              endTime: endTime,
              location: vm.ruleForm.location,
              description: vm.ruleForm.description,
              contact: vm.ruleForm.contact,
            })
              .then((data) => {
                if (data.code == 200) {
                  vm.closeDialog();
                  vm.setUpdataListByManul(new Date().getTime().toString());
                  vm.setUpdataUserEventByEdit(new Date().getTime().toString());
                } else {
                  vm.$message.warning(data.message);
                }
              })
              .catch((e) => {
                vm.closeDialog();
                console.log(e);
              });
          } else {
            createEvent({
              receiveUserId: !vm.ruleForm.id ? "" : vm.ruleForm.id,
              remuneration: vm.ruleForm.bounty,
              topic: vm.ruleForm.topic,
              startTime: startTime,
              endTime: endTime,
              location: vm.ruleForm.location,
              description: vm.ruleForm.description,
              contact: vm.ruleForm.contact,
            })
              .then((data) => {
                if (data.code == 200) {
                  vm.closeDialog();
                  vm.setUpdataListByManul(new Date().getTime().toString());
                  vm.setUpdataUserEventByEdit(new Date().getTime().toString());
                  vm.$message.success(data.message);
                } else {
                  vm.$message.warning(data.message);
                }
              })
              .catch((e) => {
                if (e.data && e.data.message) {
                  vm.$message.error(e.data.message);
                }
                vm.closeDialog();
                console.log(e);
              });
          }
        } else {
          this.$message.error(this.$t("lang.validError"));
          return false;
        }
      });
    },
    handleOpenProfile(id) {
      let routeUrl = this.$router.resolve({
        path: "/user_profile_pc",
        query: {
          id: id,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/var.scss";
.newevent-dialog-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: 999;
  background: #00000080;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .new-event-dialog {
    position: relative;
    width: 60%;
    height: 740px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px;
    background-image: url("../../../assets/dialog-bg.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    .dialog-header {
      width: calc(100% - 40px);
      position: relative;
      padding: 20px;
      text-align: center;
    }
    .event-container {
      width: 100%;
      height: calc(100% - 122px);
      margin-top: 20px;
      overflow: auto;
    }
    .el-icon-close {
      border-radius: 12px;
      position: absolute;
      right: 20px;
      top: 20px;
    }
    .event-ruleForm {
      width: 50%;
      margin-left: 25%;
      .input-wrap {
        display: flex;
        align-items: flex-start;
      }
      .invite-input {
        width: 80%;
        height: 56px;
        display: inline-block;
      }
      .invite-search {
        position: absolute;
        right: 0;
        width: 86px;
        height: 36px;
        margin: 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        background-color: $color-orange;
        color: $color-primary;
      }
      .invite-search-container {
        background: #fff;
        flex: 1;
        height: 56px;
      }
      .form-tip {
        color: $color-text-gray;
      }
      .bounty-input {
        height: 56px;
        line-height: 56px;
        width: calc(100% - 200px);
        z-index: 1;
      }
      .bounty-unit {
        position: absolute;
        display: flex;
        align-items: center;
        width: 100px;
        height: 56px;
        right: 100px;
        top: 0;
        background: $color-orange;
        border-radius: 8px;
        padding-left: 50px;
        justify-content: center;
        font-size: 2rem;
        color: $color-primary;
      }
      .event-topic {
        height: 56px;
      }
      .data-pick {
        width: 100%;
      }
      .contact-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        left: 0px;
        flex: none;
        .contact-item {
          position: relative;
          border-radius: 8px;
          &:nth-child(n + 1) {
            margin-top: 16px;
          }
          display: flex;
          justify-content: space-between;
          background: #ebebeb;

          .contact-input {
            width: calc(100% - 156px);
          }
          .el-icon-close {
            font-size: 2rem;
            right: -40px;
          }
        }
        .add-other-contact {
          background: #f6f6f6;
          border: 1px solid #131214;
          box-sizing: border-box;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 56px;
          font-size: 1.6rem;
        }
      }

      .event-control {
        display: flex;
        flex-direction: column;
        .event-submit,
        .event-cancel {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 44px;
          font-size: 1.6rem;
          border-radius: 28px;
          &:hover {
            box-shadow: 1px 1px 5px $border-color;
          }
        }
        .event-submit {
          background-color: $color-orange;
          color: $color-primary;
        }
        .event-cancel {
          border: 1px solid $color-orange;
          background-color: $color-primary;
          color: $color-text;
          font-weight: 700;
        }
      }
    }
  }
}
</style>
<style lang="scss">
@import "@/style/var.scss";
.new-event-dialog .el-input__inner {
  height: 56px;
  line-height: 56px;
  border-radius: 8px;
}
.event-control {
  margin-top: 80px;
  .el-button + .el-button {
    margin-left: 0;
    margin-top: 16px;
  }
}
.event-container .el-form-item {
  margin-bottom: 42px;
}
.other-type-name .el-input__inner {
  height: 40px;
}
.select-wrap {
  display: flex;
  align-items: center;
  .icon-wrap {
    margin-left: 1rem;
    flex: 1;
    text-align: right;
  }
}
.select-avatar {
  border-radius: 100%;
  width: 20px;
  height: 20px;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  opacity: 1;
  float: left;
}
.select-username {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10px;
  float: left;
}
</style>
